module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"halaboost","short_name":"halaboost","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"static/assets/img/logo-sm.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a3419a813fc2427b565070f675141d72"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-27R14S31RX","AW-16794372126"],"pluginConfig":{"head":false,"exclude":["/content-creator/**","/live-preview/**"],"delayOnRouteUpdate":0,"respectDNT":false,"origin":"https://www.googletagmanager.com"},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
